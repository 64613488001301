import { InputGroup, Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { Form } from 'components/form';
import { DirectoryStepProps } from 'interfaces/step-props';
import React from 'react';
import { MapAttributesStep } from './map-attributes-step';
import { ArrayStepsConfig } from './steps-config';

export const stepsFourthHr: ArrayStepsConfig = {
  providerLabel: 'FourthHR',
  type: 'Array',
  steps: [
    {
      name: 'Upload Fourth HR Organization ID, Username and Password',
      // eslint-disable-next-line react/display-name
      render: ({
        directory,
        errors,
        isLoading,
        onInputChange,
        onNextStep,
      }: DirectoryStepProps) => (
        <>
          <Text>
            Upload your Fourth HR Organization ID, Username, and Password.
            Please speak to your nominated Fourth HR contact to retrieve your
            credentials.
          </Text>

          <Card>
            <Form
              disabled={
                !directory?.fourthHrOrganizationId ||
                !directory?.fourthHrUsername ||
                !directory?.fourthHrPassword
              }
              isLoading={isLoading}
              isUpdate={
                !!(
                  errors?.fourthHrOrganizationId ||
                  errors?.fourthHrUsername ||
                  errors?.fourthHrPassword
                )
              }
              onSubmit={onNextStep}
            >
              <InputGroup
                autoFocus
                error={errors?.fourthHrOrganizationId?.message}
                id="fourthHrOrganizationId"
                label="1. Fourth HR Organization ID"
                name="fourthHrOrganizationId"
                onChange={onInputChange}
                placeholder=""
                value={directory?.fourthHrOrganizationId ?? undefined}
              />

              <InputGroup
                error={errors?.fourthHrUsername?.message}
                id="fourthHrUsername"
                label="2. Fourth HR Username"
                name="fourthHrUsername"
                onChange={onInputChange}
                placeholder=""
                value={directory?.fourthHrUsername ?? undefined}
              />

              <InputGroup
                error={errors?.fourthHrPassword?.message}
                id="fourthHrPassword"
                label="3. Fourth HR Password"
                name="fourthHrPassword"
                onChange={onInputChange}
                placeholder=""
                type="password"
                value={directory?.fourthHrPassword ?? undefined}
              />
            </Form>
          </Card>
        </>
      ),
    },
    {
      name: 'Set up Attribute Mapping',
      isCustomAttributeMapping: true,
      render: MapAttributesStep,
    },
  ],
};
