import { Button } from '@workos-inc/component-library';
import classNames from 'classnames';
import React, { FC, FormEvent, ReactNode } from 'react';

interface FormProps {
  onSubmit: () => void;
  isInline?: boolean;
  isLoading?: boolean;
  isUpdate?: boolean;
  disabled?: boolean;
  children: ReactNode;
  secondaryText?: string;
  secondaryAction?: () => void;
}

export const Form: FC<Readonly<FormProps>> = ({
  onSubmit,
  isInline,
  isLoading,
  isUpdate,
  disabled,
  children,
  secondaryText,
  secondaryAction,
}) => (
  <form
    aria-disabled={disabled}
    onSubmit={(event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmit();
    }}
  >
    <div
      className={classNames('flex', {
        'flex-col space-y-6': !isInline,
        'flex-row items-end gap-2': isInline,
      })}
    >
      {children}

      <div className="flex justify-end">
        {!!secondaryText && secondaryAction && (
          <Button
            appearance="minimal"
            className="mr-4"
            onClick={secondaryAction}
            type="button"
          >
            {secondaryText}
          </Button>
        )}

        <Button disabled={disabled} isLoading={isLoading} type="submit">
          {isUpdate ? 'Update' : 'Continue to Next Step'}
        </Button>
      </div>
    </div>
  </form>
);
