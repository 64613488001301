import {
  InputFile,
  Label,
  SelectedFile,
  Text,
} from '@workos-inc/component-library';
import { Error } from 'interfaces/errors';
import React, { FC } from 'react';

export type FileInputChangeEvent = (event: {
  name: string;
  file: SelectedFile;
}) => void;

interface FileInputProps {
  accept?: string;
  error?: Error;
  id?: string;
  label?: string;
  name: string;
  onUpload: FileInputChangeEvent;
  value?: string;
}

export const FileField: FC<Readonly<FileInputProps>> = ({
  accept = '.cer, .cert, .crt, .key, .pem',
  error,
  id = '',
  label,
  name,
  onUpload,
  value,
}) => {
  const isError = error && error.value === value;

  return (
    <fieldset>
      <Label className="mb-2" htmlFor={name}>
        {label}
      </Label>

      <InputFile
        accept={accept}
        className="break-all mt-2"
        id={id}
        label=""
        name={name}
        onChange={onUpload}
        onClear={() => onUpload({ name, file: { name, content: '' } })}
      />

      {isError && (
        <div className="text-red-darken">
          <Text inheritColor className="font-medium" size="small">
            {error?.message}
          </Text>
        </div>
      )}
    </fieldset>
  );
};
