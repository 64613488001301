import {
  ConnectionFragment as Connection,
  DirectoryCustomAttributeFragment as DirectoryCustomAttribute,
  DirectoryFragment as Directory,
  OrganizationFragment as Organization,
} from 'graphql/generated';
import { SupportedConnectionType } from 'interfaces/supported-connection-type';
import { SupportedDirectoryType } from 'interfaces/supported-directory-type';
import { useRouter } from 'next/router';
import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useFeature } from 'utils/feature-flags';
import { getTitle } from 'utils/get-title';

type Store = {
  appName: [string, Dispatch<SetStateAction<string>>];
  connection: [
    Connection | undefined,
    Dispatch<SetStateAction<Connection | undefined>>,
  ];
  domain: [string | undefined, Dispatch<SetStateAction<string | undefined>>];
  directory: [
    Directory | undefined,
    Dispatch<SetStateAction<Directory | undefined>>,
  ];
  directoryCustomAttributes: [
    DirectoryCustomAttribute[] | undefined,
    Dispatch<SetStateAction<DirectoryCustomAttribute[] | undefined>>,
  ];
  organization: [
    Organization | undefined,
    Dispatch<SetStateAction<Organization | undefined>>,
  ];
  pageTitle: [string, Dispatch<SetStateAction<string>>];
  ssoProvider: [
    SupportedConnectionType | undefined,
    Dispatch<SetStateAction<SupportedConnectionType | undefined>>,
  ];
  dsyncProvider: [
    SupportedDirectoryType | undefined,
    Dispatch<SetStateAction<SupportedDirectoryType | undefined>>,
  ];
};

/* eslint-disable @typescript-eslint/no-empty-function */
export const StoreContext = createContext<Store>({
  appName: ['', () => {}],
  connection: [undefined, () => {}],
  directory: [undefined, () => {}],
  directoryCustomAttributes: [[], () => {}],
  domain: [undefined, () => {}],
  dsyncProvider: [undefined, () => {}],
  organization: [undefined, () => {}],
  pageTitle: ['', () => {}],
  ssoProvider: [undefined, () => {}],
});
/* eslint-enable @typescript-eslint/no-empty-function */

export interface StoreProviderProps {
  appName: string;
  children: JSX.Element;
  connection?: Connection;
  domain?: string;
  directory?: Directory;
  directoryCustomAttributes?: DirectoryCustomAttribute[];
  organization?: Organization;
  ssoProvider?: SupportedConnectionType;
  dsyncProvider?: SupportedDirectoryType;
}

export const StoreProvider: FC<Readonly<StoreProviderProps>> = ({
  appName,
  children,
  connection,
  domain,
  directory,
  directoryCustomAttributes,
  organization,
  ssoProvider,
  dsyncProvider,
}) => {
  const router = useRouter();
  const hidePoweredByWorkOS = useFeature('hidePoweredByWorkos');

  const store: Store = {
    appName: useState<string>(appName),
    connection: useState<Connection | undefined>(connection),
    domain: useState<string | undefined>(domain),
    directory: useState<Directory | undefined>(directory),
    directoryCustomAttributes: useState(directoryCustomAttributes),
    organization: useState<Organization | undefined>(organization),
    pageTitle: useState<string>(''),
    ssoProvider: useState<SupportedConnectionType | undefined>(
      ssoProvider || (connection?.type as unknown as SupportedConnectionType),
    ),
    dsyncProvider: useState<SupportedDirectoryType | undefined>(
      dsyncProvider || (directory?.type as unknown as SupportedDirectoryType),
    ),
  };

  useEffect(() => {
    const [, setAppName] = store.appName;

    setAppName(appName);
  }, [appName, store.appName]);

  useEffect(() => {
    const [ssoProvider] = store.ssoProvider;
    const [dsyncProvider] = store.dsyncProvider;
    const [appName] = store.appName;
    const [directoryCustomAttributes] = store.directoryCustomAttributes;

    store.pageTitle[1](
      getTitle(
        appName,
        hidePoweredByWorkOS,
        directoryCustomAttributes ?? [],
        router.pathname,
        ssoProvider || dsyncProvider,
      ),
    );
  }, [
    store.pageTitle,
    store.ssoProvider,
    store.dsyncProvider,
    store.appName,
    store.directoryCustomAttributes,
    hidePoweredByWorkOS,
    router,
  ]);

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
