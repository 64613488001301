import { InputGroup } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { Form } from 'components/form';
import { ManualMetadataStep } from 'components/sso/steps/saml/manual-metadata-step';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { FC } from 'react';
import { unreachable } from 'utils/unreachable';

export interface MetadataUrlStepProps extends ConnectionStepProps {
  stepTitle: string;
  metadataUrlPlaceholder: string;
  idpEntityIdPlaceholder: string;
  idpSsoUrlPlaceholder: string;
}

export const MetadataUrlStep: FC<MetadataUrlStepProps> = ({
  children,
  idpEntityIdPlaceholder,
  idpSsoUrlPlaceholder,
  metadataUrlPlaceholder,
  stepTitle,
  ...connectionStepProps
}) => {
  const {
    connection,
    errors,
    isLoading,
    onInputChange,
    onNextStep,
    selectedConfiguration,
    setSelectedConfiguration,
  } = connectionStepProps;

  return (
    <Article>
      <Title className="mb-4">{stepTitle}</Title>

      {(() => {
        switch (selectedConfiguration) {
          case 'dynamic':
            return (
              <>
                {children}

                <Card>
                  <Form
                    disabled={!connection?.saml_idp_metadata_url}
                    isLoading={isLoading}
                    isUpdate={!!errors?.saml_idp_metadata_url}
                    onSubmit={onNextStep}
                    secondaryAction={() => setSelectedConfiguration('manual')}
                    secondaryText="Configure Manually"
                  >
                    <InputGroup
                      error={errors?.saml_idp_metadata_url?.message}
                      id="saml_idp_metadata_url"
                      label="IdP Metadata URL"
                      name="saml_idp_metadata_url"
                      onChange={onInputChange}
                      placeholder={metadataUrlPlaceholder}
                      value={connection?.saml_idp_metadata_url ?? undefined}
                    />
                  </Form>
                </Card>
              </>
            );
          case 'manual':
            return (
              <ManualMetadataStep
                {...connectionStepProps}
                idpEntityIdPlaceholder={idpEntityIdPlaceholder}
                idpSsoUrlPlaceholder={idpSsoUrlPlaceholder}
                onConfigurationTypeChange={setSelectedConfiguration}
              />
            );
          default:
            return unreachable(selectedConfiguration);
        }
      })()}
    </Article>
  );
};
