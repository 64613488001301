import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { MetadataXmlStep } from 'components/sso/steps/saml/metadata-xml-step';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { FC } from 'react';

export const PingFederateCreateConnection: FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 1: Create SAML SP Connection</Title>

    <Text>
      Select "Applications" from the top menu of your PingFederate Administrator
      Dashboard, then select "SP Connections".
    </Text>

    <Img
      priority
      height={1396}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-1.png"
      width={3208}
    />

    <Text>
      On the "SP Connections" page, select the "Create Connection" button to
      begin creating a SAML SP Connection.
    </Text>

    <Img
      priority
      height={988}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-2.png"
      width={2708}
    />

    <Text>
      On the "Connection Template" page, select "Do not use a template for this
      connection" and click "Next".
    </Text>

    <Img
      height={893}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-3.png"
      width={2734}
    />

    <Text>
      On the "Connection Type" page, select "Browser SSO Profiles" with the
      "SAML 2.0" Protocol, and click "Next".
    </Text>

    <Img
      height={1164}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-4.png"
      width={2801}
    />

    <Text>
      On the "Connection Options" page, select only "Browser SSO" and click
      "Next".
    </Text>

    <Img
      height={924}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-5.png"
      width={2696}
    />

    <Text>On the "Import Metadata" page, select "None" and click "Next".</Text>

    <Img
      height={802}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-6.png"
      width={2694}
    />

    <Text>
      On the "General Info" page, paste the IdP URI (Entity ID) into the
      "Partner's Entity ID (Connection ID)" field, and add a unique name in the
      "Connection Name" field, then click "Next".
    </Text>

    <CopyInput
      label="Copy this IdP URI (Entity ID)"
      value={connection?.saml_entity_id}
    />

    <Img
      height={732}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-7-edited.png"
      width={2628}
    />

    <Confirm
      label="I've configured the Entity ID. Continue to the next step."
      onClick={onNextStep}
    />
  </Article>
);

export const PingFederateConfigureBrowserSettings: FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 2: Configure Browser SSO Settings</Title>

    <Text>
      On the "Browser SSO" page, select the "Configure Browser SSO" button.
    </Text>

    <Img
      priority
      height={798}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-8.png"
      width={2678}
    />

    <Text>
      On the "SAML Profiles" page, select "SP-initiated SSO" under the "Single
      Sign-On (SSO) Profiles", and then click "Next".
    </Text>

    <Img
      priority
      height={854}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-9.png"
      width={2668}
    />

    <Text>Configure the Assertion Lifetime and click "Next".</Text>

    <Img
      height={842}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-10.png"
      width={2682}
    />

    <Confirm
      label="I've finished configuring the Browser SSO Settings
          and clicked 'Next'."
      onClick={onNextStep}
    />
  </Article>
);

export const PingFederateConfigureAssertionCreation: FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 3: Configure Assertion Creation</Title>

    <Text>
      On the "Assertion Creation" page, select the "Configure Assertion
      Creation" button.
    </Text>

    <Img
      priority
      height={1134}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-11.png"
      width={2684}
    />

    <Text>
      On the Identity Mapping page, select the "Standard" option and click
      "Next".
    </Text>

    <Img
      priority
      height={1194}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-12.png"
      width={2684}
    />

    <Text>
      On the "Attribute Contract" page, define at least id, email, firstName and
      lastName attributes, as shown below, and then click "Next".
    </Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Img
      height={1366}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-13.png"
      width={2688}
    />

    <Text>
      On the "Authentication Source Mapping" page, define the attribute mapping
      for your SAML setup. This can vary based on how you have PingFederate
      configured. Below, we describe an example that uses an Authentication
      Policy and user information from an LDAP server.
    </Text>

    <Img
      height={1052}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-14.png"
      width={2688}
    />

    <Text>
      On the "Authentication Policy Mapping" setup, we define the following
      "Attribute Contract Fulfillment" settings to map the attributes to content
      from the policy or the LDAP server.
    </Text>

    <Img
      height={1486}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-15.png"
      width={2702}
    />

    <Confirm
      label="I've configured the SAML Assertion. Continue to next step."
      onClick={onNextStep}
    />
  </Article>
);

export const PingFederateConfigureProtocolSettings: FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 4: Configure Protocol Settings</Title>

    <Text>
      Navigate to the "Protocol Settings" page and select the "Configure
      Protocol Settings" button.
    </Text>

    <Img
      priority
      height={1152}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-16.png"
      width={2694}
    />

    <Text>
      On the "Assertion Consumer Service URL" page, paste the ACS URL into the
      Endpoint URL field with a POST binding, then click "Next".
    </Text>

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Img
      priority
      height={932}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-17-edited.png"
      width={2686}
    />

    <Text>
      On the "Allowable SAML Bindings" page, check at least POST and REDIRECT,
      then click "Next".
    </Text>

    <Img
      height={932}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-18.png"
      width={2686}
    />

    <Text>
      On the "Signature Policy" page, select "Always Sign Assertion", then click
      "Next".
    </Text>

    <Img
      height={880}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-19.png"
      width={2686}
    />

    <Text>
      On the "Encryption Policy" page, select "None", then click "Next".
    </Text>

    <Img
      height={1532}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-20.png"
      width={2702}
    />

    <Confirm
      label="I've configured the SAML Protocol Settings. Continue to next step."
      onClick={onNextStep}
    />
  </Article>
);

export const PingFederateConfigureCredentials: FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 5: Configure Credentials</Title>

    <Text>
      Navigate to the "Credentials" page, and select the "Configure Credentials"
      button.
    </Text>

    <Img
      priority
      height={846}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-21.png"
      width={2690}
    />

    <Text>
      On the "Digital Signature Settings" page, select a signing certificate and
      the RSA SHA256 signing algorithm, then click "Done".
    </Text>

    <Img
      priority
      height={1064}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-22.png"
      width={2698}
    />

    <Confirm
      label="I've configured the SAML Credentials. Continue to next step."
      onClick={onNextStep}
    />
  </Article>
);

export const PingFederateUploadData: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataXmlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://auth.workos.com/AbC123..."
    idpSsoUrlPlaceholder="https://mypingfederate.com/idp/SSO.saml2"
    stepTitle="Step 6: Upload Identity Provider Metadata"
  >
    <Text>
      On the SP Connection list, find your WorkOS SAML 2.0 connection. Click on
      the "Select Action" menu and then select "Export Metadata" to download the
      connection metadata.
    </Text>

    <Img
      priority
      height={1121}
      src="/admin-portal/sso/ping-federate/v1/ping-federate-23.png"
      width={2666}
    />
  </MetadataXmlStep>
);
