import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { StoreContext } from 'components/store-provider';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { useContext } from 'react';

export const MiniOrangeSAMLIntegration: React.FC<
  Readonly<Pick<ConnectionStepProps, 'onNextStep'>>
> = ({ onNextStep }) => {
  const {
    connection: [connection],
  } = useContext(StoreContext);

  return (
    <Article>
      <Title className="mb-4">Step 1: Create SAML Integration</Title>

      <Text>
        From the miniOrange / Xecurify dashboard, select "Apps" on the left side
        navigation. Then, select "Add Application".
      </Text>

      <Img
        height={693}
        src="/images/8d1af392-b852-4d06-9563-99345e8f1f0b.png"
        width={1102}
      />

      <Text>Under "SAML/WS-FED", select "Create App".</Text>

      <Img
        height={729}
        src="/images/cc2bfa62-2b2b-4eb0-80b9-9a8269499924.png"
        width={1278}
      />

      <Text>
        Search for "custom" in the search box and select "Custom SAML App".
      </Text>

      <Img
        height={733}
        src="/images/e63a9617-6cb4-4f06-9600-9f178136ea16.png"
        width={1282}
      />

      <Text>
        Under the "Basic Settings" tab of the SAML app, select "Import SP
        Metadata".
      </Text>

      <Img
        height={807}
        src="/images/6ddfd48a-4945-4b58-b801-6938e5b675b1.png"
        width={1283}
      />

      <Text>
        Give the SAML app a descriptive name under "App Name". Under "SP
        Metadata", select "URL" and input the SP Metadata URL below. Then, hit
        "Import".
      </Text>

      <CopyInput
        label="Copy this URL"
        value={connection?.saml_sp_metadata_url}
      />

      <Img
        height={309}
        src="/images/7a07b8ca-4858-4a79-a6f4-e07d0e8b9a43.png"
        width={657}
      />

      <Text>
        Make sure that you have the "Sign Assertion" field toggled on.
      </Text>

      <Img
        height={53}
        src="/images/1f64470e-97e9-4903-88e5-70a41134e501.png"
        width={310}
      />

      <Text>Select "Next".</Text>

      <Img
        height={852}
        src="/images/46a528c5-28c8-432f-8a87-57f4aef6f2a3.png"
        width={1288}
      />

      <Confirm
        label="I've configured the Single sign on URL and Audience URI."
        onClick={onNextStep}
      />
    </Article>
  );
};

export const MiniOrangeAttributeStatements: React.FC<
  Readonly<Pick<ConnectionStepProps, 'onNextStep'>>
> = ({ onNextStep }) => {
  const {
    connection: [connection],
  } = useContext(StoreContext);

  return (
    <Article>
      <Title className="mb-4">Step 2: Configure Attribute Statements</Title>

      <Text>
        Under the "Attribute Mapping" section of the SAML app, select "Add
        Attribute".
      </Text>

      <Img
        height={536}
        src="/images/b2b95c1b-f721-41a6-8df7-7b4edec8d551.png"
        width={968}
      />

      <Text>
        Map the following four attributes as shown below, and the select "Save".
      </Text>

      <ConnectionAttributeMapping connectionType={connection?.type} />

      <Img
        height={238}
        src="/images/c1ca80a2-5e76-4c94-b61f-a0dcd30f0259.png"
        width={499}
      />

      <Confirm
        label="I've finished configuring the Attribute Statements
        and clicked “Save”."
        onClick={onNextStep}
      />
    </Article>
  );
};

export const MiniOrangeIdentityProviderMetadata: React.FC<
  Readonly<ConnectionStepProps>
> = (connectionStepProps) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://login.xecurify.com/moas/..."
    idpSsoUrlPlaceholder="https://login.xecurify.com/moas/idp/samlsso/..."
    metadataUrlPlaceholder="https://login.xecurify.com/moas/metadata/saml/..."
    stepTitle="Step 3: Upload Identity Provider Metadata"
  >
    <Text>
      The final step for implementing SAML SSO requires sharing your identity
      provider's metadata with the application.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Text>
      Back on the "Apps" tab of the miniOrange Dashboard, click "Select" next to
      the app you've created. From the dropdown, select "Metadata".
    </Text>

    <Img
      height={312}
      src="/images/5a23af02-ad1b-4546-abba-e662ead690b2.png"
      width={952}
    />

    <Text>
      Under the "INFORMATION REQUIRED TO SET MINIORANGE AS IDP" section, click
      the icon next to "Metadata URL" to copy it to your clipboard.
    </Text>

    <Img
      height={321}
      src="/images/f6712646-9a0d-4303-8186-674cc415a4c7.png"
      width={967}
    />

    <Text>
      Input the IdP Metadata URL below and select "Continue to Next Step".
    </Text>
  </MetadataUrlStep>
);
