import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { FC } from 'react';

export const CyberArkCreateIntegration: FC<Readonly<ConnectionStepProps>> = ({
  appName,
  connection,
  onNextStep,
}) => (
  <Article>
    <Title className="mb-4">Step 1: Create SAML Integration</Title>

    <Img
      priority
      height={775}
      src="/admin-portal/sso/cyber-ark-saml/v1/cyber-ark-saml-4.png"
      width={1522}
    />

    <Text>
      Log in to the CyberArk Identity Admin Portal and select "Web Apps" from
      the left-side navigation.
    </Text>

    <Img
      priority
      height={652}
      src="/admin-portal/sso/cyber-ark-saml/v1/cyber-ark-saml-5.png"
      width={1525}
    />

    <Text>Select "Add Web Apps" to begin creating a new SAML application.</Text>

    <Img
      height={667}
      src="/admin-portal/sso/cyber-ark-saml/v1/cyber-ark-saml-6.png"
      width={895}
    />

    <Text>Select the "Custom" tab and then click to add "SAML".</Text>

    <Img
      height={452}
      src="/admin-portal/sso/cyber-ark-saml/v1/cyber-ark-saml-7.png"
      width={433}
    />

    <Text>Select "Yes" to begin setting up the SAML App.</Text>

    <Img
      height={846}
      src="/admin-portal/sso/cyber-ark-saml/v1/cyber-ark-saml-8-edited.png"
      width={1164}
    />

    <Text>
      Enter a descriptive App Name (such as {appName}) and a Description, then
      click "Save".
    </Text>

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Text>
      On the "Trust" tab of the SAML App, go to the "Service Provider
      Configuration Section" and select "Manual Configuration". Input the ACS
      URL above as both the "SP Entity Id / Issuer / Audience" and "Assertion
      Consumer Service (ACS) URL".
    </Text>

    <Img
      height={794}
      src="/admin-portal/sso/cyber-ark-saml/v1/cyber-ark-saml-9-edited.png"
      width={1186}
    />

    <Text>
      IMPORTANT: Be sure to check "Both" under "Sign Response or Assertion?"
    </Text>

    <Confirm
      label="I’ve configured the SP Entity ID / Issuer / Audience and ACS URL."
      onClick={onNextStep}
    />
  </Article>
);

export const CyberArkConfigureAttributeStatements: FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 2: Configure Attribute Statements</Title>

    <Img
      priority
      height={841}
      src="/admin-portal/sso/cyber-ark-saml/v1/cyber-ark-saml-11.png"
      width={1190}
    />

    <Text>
      Select the "SAML Response" tab and use the "Add" button to add the
      following key-value pairs. Then, click "Save".
    </Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Confirm
      label="I’ve finished configuring the Attribute Statements
          and clicked “Save”."
      onClick={onNextStep}
    />
  </Article>
);

export const CyberArkAddUsers: FC<Readonly<ConnectionStepProps>> = ({
  onNextStep,
}) => (
  <Article>
    <Title className="mb-4">Step 3: Add Users to SAML App</Title>

    <Text>
      To give users permission to authenticate via this SAML app, you will need
      to assign individual users and/or groups of users to the CyberArk SAML
      app.
    </Text>

    <Img
      priority
      height={840}
      src="/admin-portal/sso/cyber-ark-saml/v1/cyber-ark-saml-12.png"
      width={1200}
    />

    <Text>Click on the "Permissions" tab, and select "Add"</Text>

    <Img
      height={594}
      src="/admin-portal/sso/cyber-ark-saml/v1/cyber-ark-saml-14.png"
      width={757}
    />

    <Text>
      Search for the individual user(s) and/or group(s) that you would like to
      assign to the app, and check the box next to them. Click "Add" when you
      are finished. Once users have been successfully added, you should also
      notice the "Status" of your CyberArk SAML app change to "Deployed".
    </Text>

    <Confirm
      label="I’ve users and/or groups to the SAML app."
      onClick={onNextStep}
    />
  </Article>
);

export const CyberArkUploadMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="http://www.cyberark.com/exkgq2c19CUpt2Brr46"
    idpSsoUrlPlaceholder="http://www.cyberark.com/exkgq2c19CUpt2Brr46"
    metadataUrlPlaceholder="https://cyberark-metadata-url"
    stepTitle="Step 4: Upload Identity Provider Metadata"
  >
    <Text>
      You can find the Metadata URL under the "Trust" tab of the SAML app in the
      "Identity Provider Configuration" section. Enter the value into the field
      below.
    </Text>

    <Img
      priority
      height={700}
      src="/admin-portal/sso/cyber-ark-saml/v1/cyber-ark-saml-17.png"
      width={1100}
    />
  </MetadataUrlStep>
);
