import { Button, Label } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { Link } from 'components/link';
import { Spinner } from 'components/spinner';
import React, { FC, useState } from 'react';
import { File } from 'react-feather';

type DownloadInputProps = {
  filename: string;
  label: string;
  value?: string;
};

export const DownloadInput: FC<Readonly<DownloadInputProps>> = ({
  filename,
  label,
  value,
}) => {
  const [downloaded, setDownloaded] = useState<boolean>(false);

  const handleDownload = (): void => {
    setTimeout(() => setDownloaded(false), 2000);

    setDownloaded(true);
  };

  return (
    <Card>
      <Label htmlFor="">{label}</Label>

      <div className="flex items-center justify-between mt-2">
        {value ? (
          <>
            <Link appearance="highlight" download={filename} href={value}>
              <File className="mr-2 inline-flex" size={16} />
              {filename}
            </Link>

            <Link download={filename} href={value}>
              <Button
                appearance={downloaded ? 'minimal' : undefined}
                onClick={handleDownload}
              >
                {downloaded ? 'Downloaded!' : 'Click to download'}
              </Button>
            </Link>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </Card>
  );
};
